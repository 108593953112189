<template>
<div>
  <div class="content" >
<!-- department-query 部门搜索 -->
  <department-query  @clearDepartment="getList()" @changeDepartment="changeDepartment($event)" ></department-query>
    <!-- <div class="header-item">
        <div class="item-content">
          <label>部门：</label>
          <el-select v-model="query.teamId" placeholder="请选择部门"  clearable @clear="getList">
            <el-option v-for="item in teamsList"
              :key="item.id"
              :label="item.label" 
              :value="item.id">
            </el-option>
          </el-select>
        </div>
    </div> -->
    <div class="header-item">
        <div class="item-content">
         <label></label>
          <el-input style="display: inline-block; "
            v-model="query.name" placeholder="请输入姓名" clearable @clear="getList"></el-input>
        </div>
    </div>
    <div class="header-item">
          <el-button type="primary"  icon="el-icon-search" @click="getList()">搜索</el-button>
    </div>
     <!--table公司名片表格  -->
      <el-table  tooltip-effect="dark" text-align='center' :data="dataList"  align="center"
      class="tableBox" style="width: 100%; margin-top: 10px"
       @sort-change="changeTableSort"
      header-row-class-name="table-header-style"
      :header-cell-style="{'background-color': 'rgba(249, 249, 249)'}">

        <el-table-column label="头像"  width="70" fixed >
           <template slot-scope="scope">
            <img style="width: 40px;height:40px" 
              :src="scope.row.head_img || $configs.headImg"> 
          </template>
        </el-table-column>
        <el-table-column label="姓名" width="99" fixed>
          <template slot-scope="scope">
            {{scope.row.name || '无'}}
          </template>
        </el-table-column>
        <el-table-column label="手机号" width="130" fixed>
          <template slot-scope="scope">
            {{scope.row.phone_number || '无'}}
          </template>
        </el-table-column>
        <el-table-column label="部门" width="240" fixed>
           <template slot-scope="scope">
            {{scope.row.teamName || '无'}}
          </template>
        </el-table-column>
        <el-table-column label="职位" width="200" fixed>
          <template slot-scope="scope">
            <span>{{scope.row.position || '无'}}</span>
          </template>
        </el-table-column>
        <el-table-column label="状态"   fixed>
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.status"
              :active-value="1"
              :inactive-value="0"
              active-color="#409EFF"
              inactive-color="#999999">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="地址"  width="400"  >
           <template slot-scope="scope">
              <el-tooltip :open-delay='300' >
              <span>{{scope.row.address || '无'}}</span>
              <div slot="content">
                <span>{{scope.row.address || '无'}}</span>
              </div>
              </el-tooltip>
          </template>
        </el-table-column>
        <!-- el-icon-sort -->
        <el-table-column label="人气数" align="center"  width="89" prop="visit" :sortable="'custom'" >
           <template slot-scope="scope">
            {{scope.row.visit || 0}}
          </template>
        </el-table-column>
        <el-table-column label="收藏数" align="center" width="89"  prop="collect"  :sortable="'custom'">
           <template slot-scope="scope">
            {{scope.row.collect || 0}}
          </template>
        </el-table-column>
        <el-table-column label="喜欢数" align="center"  width="89" prop="raiseup" :sortable="'custom'" >
          <template slot-scope="scope">
            {{scope.row.raiseup || 0}}
          </template>
        </el-table-column>
        <el-table-column label="操作"  width="76" >
          <template slot-scope="scope">
            <!-- {{scope.row.position || ' 无'}} -->
            <el-button  type="primary" @click="viewCard(scope.row)" size="mini">查看</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 查看的名片详细列表 -->
      <el-dialog title="公司名片" width="70%" :visible.sync="applyTableVisible">
        <div >
          <!-- <div class="breadcrumb">
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item>{{$route.query.name || '您'}}的名片</el-breadcrumb-item>
            </el-breadcrumb>
          </div> -->
          <div class="header-item" style="margin-left:20px">
            <div class="item-content">
              <label>操作：</label>
              <el-select v-model="selectdetailValue"  placeholder="全部" clearable @clear="getdetailList">
                <el-option v-for="item in operationList"
                  :key="item.id"
                  :label="item.label" 
                  :value="item.id">
                </el-option> 
              </el-select>
            </div>
          </div>
          <div class="header-item">
            <div class="item-content">
              <label></label>
              <el-input style="display: inline-block; "
                placeholder="请输入姓名"></el-input>
            </div>
          </div>
          <div class="header-item">
            <el-button type="primary"  icon="el-icon-search" @click="getdetailList">搜索</el-button>
          </div>
            <!--table公司名片表格  -->
          <el-table  tooltip-effect="dark" text-align='center' :data="currentdetailList"  align="center"
          class="tableBox" style="width: 100%; margin-top: 10px"
          header-row-class-name="table-header-style"
          :header-cell-style="{'background-color': 'rgba(249, 249, 249)'}">
            <el-table-column label="头像"  width="70" fixed >
                <template slot-scope="scope">
                <img style="width: 40px;height:40px" 
                  :src="scope.row.avatar || $configs.headImg"> 
              </template>
            </el-table-column>
            <el-table-column label="姓名" width="80" >
              <template slot-scope="scope">
                {{scope.row.nickname || '李鑫'}}
              </template>
            </el-table-column>
            <el-table-column label="手机号" width="110" >
              <template slot-scope="scope">
                {{scope.row.tel || '138xxxxxxxx'}}
              </template>
            </el-table-column>
            <!-- 字段暂缺 -->
            <el-table-column label="职位" width="140" >
              <template slot-scope="scope">
                {{scope.row.position || '客户经理'}}
              </template>
            </el-table-column>
            <!-- 字段暂缺 -->
            <el-table-column label="公司"   >
                <template slot-scope="scope">
                {{scope.row.address || '北京大龙科技'}}
              </template>
            </el-table-column>
              <el-table-column label="时间"  width="180" >
                <template slot-scope="scope">
                {{scope.row.last_visit_time | dateFormat('YYYY-MM-DD HH:mm:ss')}}
              </template>
            </el-table-column>
              <el-table-column label="操作"   width="80">
                <template slot-scope="scope">
                {{scope.row.address || '查看'}}
              </template>
            </el-table-column>
              <el-table-column label="关联"   width="60"  >
                <template slot-scope="scope">
                {{scope.row.address || '游客'}}
              </template>
            </el-table-column>

          </el-table>
          <!-- <div class="tolal">
            共{{detailtotal}}位
          </div> -->
          <!-- 查看列表分页 -->
          <div style="margin-top: 20px; overflow: hidden">
            <div style="float: right">
              <el-pagination @current-change="handledetailList" layout="total, prev, pager, next, jumper"
                :background="true"
                :current-page="detail.pageIndex"
                :page-size="detail.pageNumber"
                :total="detailtotal">
              </el-pagination>
            </div>
          </div>
        </div>
      </el-dialog>



  <!-- 分页 -->
      <div class="pagination">
        <el-pagination
          :background="true"
          @current-change="getList"
          :current-page.sync="query.pageIndex"
          :page-size="query.pageNumber"
          layout="total, prev, pager, next, jumper"
          :total='plazaCardTotal'>
        </el-pagination>
      </div>
  </div>

</div>
</template>
<script>
import DepartmentQuery from '@/websites/cms/components/DepartmentQuery'
export default {
  data() {
    return {
      dataList:[],//团队成员列表
      // currentList:[],//当页显示的成员列表
      query:{
        pageIndex:1,
        pageNumber:6,
        name:'',//公司名称或者姓名
        orderParam:'',//排序的字段
        sort:'',//排序的方式
        province:'',//省份 
        city:'',//城市 
        district:'',//区县
        industryTag:'',//行业
        teamId:''
      },
      teamsList:[],//部门列表
      plazaCardTotal:0,
      params: {
        times: [
          this.$dayjs().subtract(30, 'day').format('YYYY-MM-DD'),
          this.$dayjs().format('YYYY-MM-DD')
        ],
      },
      applyTableVisible:false,//dialog查看详情
      detailparams: {
        size: 1000,
        after: '',
      },
      datadetailList:[],//名片详情全部表格数据
      currentdetailList:[],//名片详情当前表格数据
      operationList:[
        {id:'',label:'全部'},
        {id:'collect',label:'收藏'},
        {id:'raiseup',label:'点赞'},
        {id:'visit',label:'查看'},
      ],
      selectdetailValue:'',//操作列表select 收藏点赞查看，
      detailtotal:0,
      xid:'',//点击查看当前销售ID
      detail:{//查看列表分页数据
        pageIndex:0,
        pageNumber:6,
      }
    }
  },
  components: {
    'department-query':DepartmentQuery
  },
  created() {
    this.getList()
    this.getMyTeams();
  },
  methods: {
    //获取企业成员列表
    async getList(val) {
      this.query.pageIndex = val;
      for(var key in this.query) {
        if(this.query[key] == '') {
          delete this.query[key]
        }
      }
      let res = await this.$ZGManager.companyCardList(this.query) 
      if (res.status!=200) {
        return
      }
      this.dataList = res.data.list;
      this.plazaCardTotal = res.data.total_rows;
    },
      // 获取公司信息
    async getCompany(){
      let res = await this.$ZGManager.getMyCompany();
      if(res.status == 200){
        this.teamsList.push({
          id: res.data.id,
          label: res.data.shortName || '全部人员',
        });
        // this.getMyTeams();
      }
    },
    // 获取我的团队列表
    async getMyTeams(){
      let res = await this.$ZGManager.getMyTeams();
      if(res.status == 200){
        res.data.map((e) => {
          this.teamsList.push({
            id: e.id,
            label: e.name,
          });
        });
      }
      this.teamsList.unshift({
        id:'',
        label:'全部部门'
      })
      // console.log(this.teamsList);
      // this.teamsList.pop();
    },
    //跳转到获取名片详情
    async viewCard(row) {  
      console.log(row);
      this.applyTableVisible=true;
      this.xid = row.id;
      this.getdetailList(row.id)
    },
    async getdetailList(id) {
      console.log(id);
      let query = {
        xid:this.xid,
        evt:this.selectdetailValue,
        rt:'card',
        status:1,
      }
      // console.log(query);
      let res = await this.$ZGManager.statCustomerList(query,this.detailparams)
      console.log(res);
      if (res.status == 200) {
        this.datadetailList = res.data.list;
        this.detailtotal = this.datadetailList.length;
        this.currentdetailList = this.datadetailList.slice(0,6)
      }
    },
    //改变排序方式
    changeTableSort(column) {
      console.log(column);
      this.query.orderParam = column.prop;
      if (column.order == 'ascending') {
        this.query.sort = 'asc';
      }else if(column.order == 'descending') {
        this.query.sort = 'desc'
      }
      this.getList()
    },
    //查看分页
    handledetailList(val) {
      console.log(val);
      this.detail.pageIndex = val;
      this.currentdetailList = this.datadetailList.slice((this.detail.pageIndex-1) * this.detail.pageNumber, this.detail.pageIndex * this.detail.pageNumber)
      console.log(this.currentdetailList);
    },
  // 获取部门id
    changeDepartment(val) {
      // console.log(val);
      this.query.teamId= val;
    }

  },
}
</script>
<style lang="scss" scoped>
.content{
  padding: 10px;
  background-color: #fff;
  margin-bottom: 50px;
  font-size: 14px;
   ::v-deep .cell{
    // width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
   .pagination {
    float: right;
    margin-top: 20px;
  }
  .breadcrumb {
    padding-bottom: 20px;
    span {
      &:nth-child(1) {
        cursor: pointer;
      }
      &:nth-child(1):hover {
       color: #409EFF;
      }
    }
  }
}
.header-item{
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
  vertical-align: middle;
}
img {
  border-radius: 50%;
}
</style>